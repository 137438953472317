
// Author's custom styles
// we need to import this first so that it would override bootstrap scss variables later
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&family=Titillium+Web:wght@400;700&display=swap');
@import "block";
@import "variables";
@import "form";
@import "header";
@import "footer";
@import "~bootstrap/scss/bootstrap.scss";
@import '@splidejs/splide/css';
@import "~glightbox/dist/css/glightbox.css";

.gradient{
    background: linear-gradient(90deg, rgba(13,219,148,1) 0%, rgba(9,168,186,1) 72%, rgba(3,82,250,1) 100%);
}

.heading-font{
    font-family: $heading-font;
}
.cta-rounded{
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after{
        overflow: hidden;
        content:"";
        position: absolute;
        z-index: -1;
        left:0;
        right:0;
        aspect-ratio: 1/1;
        background: linear-gradient(90deg, rgba(13,219,148,1) 0%, rgba(9,168,186,1) 72%, rgba(3,82,250,1) 100%);
        clip-path: ellipse(50% 35% at 50% 50%);
        @include media-breakpoint-down(md){
        
            clip-path:none;
        }
    }
   
}

.btn{
    clip-path: circle(63%);
    padding: .5rem 2rem;
    border-radius: 0;
    transition: all .75s ease-in-out;
    &:hover{
        clip-path: circle(100%);
    }
}
.btn-link{
    display: flex;
    gap: 1rem;
    color: $blue;
    padding-left: 0;
    clip-path: none;
    &::after{
        content: url('../assets/img/arrow.svg');
        transition: transform .3s ease;
    }    
    &:hover{
        color: $blue;
        &::after{
            transform: translateX(10px);
        } 
    }
}

.btn-primary{
    --bs-btn-hover-bg:var(--bs-blue);
    --bs-btn-hover-border-color:var(--bs-blue);
    --bs-btn-hover-color:white;
   
}


.btn-secondary{
    --bs-btn-hover-bg:var(--bs-green);
    --bs-btn-hover-border-color:var(--bs-green);
    --bs-btn-hover-color:white;
   
}

.content-list-wrapper{
    & > div{
        position: relative;
        overflow: hidden;
        .rectangle{
    
            position: absolute;
            pointer-events: none;
            left:0;
        }

        &:nth-child(odd){
            background-color: #F5F5F5;
            .rectangle{
    
                width: auto;
                height: 55%;
            }
        }
        &:nth-child(even){
            .rectangle{

                width: auto;
                height: 90%;
               
            }
        }
    }
}
.inquiry-form{
    background-color: #F5F5F5;
    
    .form-wrapper{
        margin: 2rem auto;
        background-color: #fff;
        max-width: 1500px;
        padding: 2rem;
    }
}
.pagination{
    --bs-pagination-bg:transparent;
    --bs-pagination-border-color:transparent;
    --bs-pagination-color:var(--bs-black);
    --bs-pagination-hover-color:var(--bs-blue);
    --bs-pagination-active-bg:transparent;
    --bs-pagination-active-color:var(--bs-blue);
    --bs-pagination-active-border-color: transparent;
}
.page-item{
    font-weight: 500;
    .page-link{
        height: 100%;
        display: flex;
        align-items: center;
    }
}
.page-item.disabled{
    display: none;
}
.page-item.active{

    .page-link{
        border-bottom: 1px solid blue;
        width: auto;
        padding: 0 5px;
    }

}
.pager-arrow-a{
    border:none!important;
    border-radius: 0!important;
}
.arrow-prev .pager-arrow-icon{
    background:none;
    content: url('../assets/img/left.svg');
}

.arrow-next .pager-arrow-icon{
    background:none;
    content: url('../assets/img/right.svg');
}

.share-bar{
    svg{
        height: 25px;
        width: 25px;
        object-fit: contain;
    }
    a{
        transition: color .3s ease;
        &:hover{
            color:$green!important;
        }
    }
}

#splash-wrap.splash-plain{
    background: url('../assets/img/splash_bg_light.png');
    background-size: contain;
    background-position: top 50px right 0px;
    background-repeat: no-repeat;
}
