// For complete list of CSS variables (bootstrap 5.3)
// https://getbootstrap.com/docs/5.3/customize/css-variables/

// For complete list of SCSS variables 
// @import "../../node_modules/bootstrap/scss/_variables.scss";

// Theme colors

$enable-negative-margins: true; 

$green: #0DDB94;
$blue: #0352FA;
$black: #393B3C;
$white: #fff;
$offwhite: #E8E8E8;
$primary: $green;
$secondary: $blue;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "green": $green,
  "blue": $blue,
  "black": $black,
  "white": $white,
  "offwhite": $offwhite,
);

// Fonts



$font-family-base: "Public Sans", sans-serif;
$font-size-base: 1rem;
$font-size-root: 18px;

$heading-font: "Titillium Web", sans-serif;
$headings-color: $black;
// Links
$link-color: #26658d;
$link-decoration: none;

// Navbar
$navbar-light-icon-color: $black;
$navbar-light-color:$black;
$navbar-light-active-color:$black;
$navbar-light-hover-color: $black;
// Font size

$h1-font-size: 3.389rem; //61px
$h2-font-size: 2.5rem; //45px
$h3-font-size: 2rem; //36px
$h4-font-size: 1.667rem; //30px
$h5-font-size: 1.333rem; //24px
$h6-font-size: 1.167rem; //21px


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1363px
);

$offcanvas-padding-y: 0;
$offcanvas-padding-x: 0;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 9,
);
//Pagination
$accordion-button-active-bg: transparent;
$accordion-button-hover-bg: transparent;

$accordion-button-active-color: $blue;
$accordion-icon-active-color: $blue;
$accordion-icon-transform:rotate(90deg);
$accordion-button-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="15" viewBox="0 0 18 15"><defs><clipPath id="a"><rect width="18" height="15" fill="#393b3c"/></clipPath></defs><g clip-path="url(#a)"><path d="M17.623,6.805a1.285,1.285,0,0,1,0,1.818l-6.429,6.429a1.286,1.286,0,0,1-1.818-1.818L13.611,9H1.286a1.286,1.286,0,1,1,0-2.571H13.611L9.377,2.194A1.286,1.286,0,0,1,11.195.376Z" fill="#{$black}"/></g></svg>');
$accordion-button-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="15" viewBox="0 0 18 15"><defs><clipPath id="a"><rect width="18" height="15" fill="#393b3c"/></clipPath></defs><g clip-path="url(#a)"><path d="M17.623,6.805a1.285,1.285,0,0,1,0,1.818l-6.429,6.429a1.286,1.286,0,0,1-1.818-1.818L13.611,9H1.286a1.286,1.286,0,1,1,0-2.571H13.611L9.377,2.194A1.286,1.286,0,0,1,11.195.376Z" fill="#{$accordion-icon-active-color}"/></g></svg>');